export const sources = [
  {
    title: "100% Retro",
    quality: "256kbps",
    server_url: "https://server.musicstars.online",
    source: "https://server.musicstars.online/radio/8000/listen",
    station_id: 1,
    station_shortcode: "100procentretro",
  },
  {
    title: "100% Retro",
    quality: "192kbps",
    server_url: "https://server.musicstars.online",
    source: "https://server.musicstars.online/radio/8000/listen_192",
    station_id: 1,
    station_shortcode: "100procentretro",
  },
  {
    title: "100% Retro",
    quality: "128kbps",
    server_url: "https://server.musicstars.online",
    source: "https://server.musicstars.online/radio/8000/listen_128",
    station_id: 1,
    station_shortcode: "100procentretro",
  },
  {
    title: "100% Retro",
    quality: "96kpbs",
    server_url: "https://server.musicstars.online",
    source: "https://server.musicstars.online/radio/8000/listen_lq",
    station_id: 1,
    station_shortcode: "100procentretro",
  },
  {
    title: "Gold",
    quality: "192kbps",
    server_url: "https://server.musicstars.online",
    source: "https://server.musicstars.online/radio/8010/live",
    station_id: 2,
    station_shortcode: "gold",
  },
  // {
  //   title: "Summer",
  //   quality: "128kbps",
  //   server_url: "https://server.musicstars.online",
  //   source: "https://server.musicstars.online/radio/8020/radio.mp3",
  //   station_id: 19,
  //   station_shortcode: "summer",
  // },
  {
    title: "Back-up 100% Retro",
    quality: "192kbps",
    server_url: "https://cstmn.gr",
    source: "https://cstmn.gr/radio/8050/stream.mp3",
    station_id: 6,
    station_shortcode: "starradio_gold",
  },
  {
    title: "Back-up 100% Retro",
    quality: "96kpbs",
    server_url: "https://cstmn.gr",
    source: "https://cstmn.gr/radio/8050/mobile.mp3",
    station_id: 6,
    station_shortcode: "starradio_gold",
  },
]

export default sources;