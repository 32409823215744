import "@babel/polyfill";
import "es6-promise/auto";
import Vue from "vue";

import Vuex from "vuex";
Vue.use(Vuex);

import App from "./App.vue";
import VueRouter from "vue-router";
// VueNativeSock = VueNativeSock.default;
Vue.use(VueRouter);

import autosize from "v-autosize";
Vue.use(autosize);

import axios from "axios";
Vue.prototype.$http = axios;

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

import VueOffline from "vue-offline";
Vue.use(VueOffline, { storage: false, mixin: false });

import "@/assets/css/app.scss";

import HomePage from "@/components/Pages/HomePage";
// import ProgramList from "@/components/Pages/ProgramList";
// import ExtraPage from "@/components/Pages/ExtraPage";
import PageNotFound from "@/components/Pages/PageNotFound";

import sources from '@/sources'

import "./registerServiceWorker";

Vue.config.productionTip = false;


const store = new Vuex.Store({
  state: {
    sources: sources,
    station: [],
    song_history: [],
    now_playing: {},
  },
  getters: {
    station: (state) => state.station,
    song_history: (state) => state.song_history,
    now_playing: (state) => state.now_playing,
  },
  mutations: {
    /*updateHistory (state, history) {
      // console.log('vuex updateHistory called',  state, history);
      Vue.set(state, 'history', history);
    },
    updateNowPlaying (state, history) {
      // console.log('vuex updateHistory called',  state, history);
      Vue.set(state, 'history', history);
    },*/
    updateInfo(state, info) {
      Vue.set(state, "station", info.station);
      Vue.set(state, "song_history", info.song_history);
      Vue.set(state, "now_playing", info.now_playing);
    },
  },
});

const router = new VueRouter({
  mode: "history",
  base: __dirname,
  // linkExactActiveClass: 'is-active',
  routes: [
    { path: "/", name: "home", component: HomePage },
    // { path: "/programmering", name: "programmering", component: ProgramList },
    // { path: "/extra", name: "extra", component: ExtraPage },
    {
      path: "*",
      component: PageNotFound,
    },
  ],
});
// router.replace({ path: '/', redirect: '*' })

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
