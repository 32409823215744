<template>
  <div class="flex items-center justify-center w-full h-full">
    <div class="px-40 py-20 bg-white rounded-md shadow-xl">
      <div class="flex flex-col items-center">
        <h1 class="text-6xl font-bold text-ydlbrown">404</h1>

        <h6
          class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl"
        >
          <span class="text-orangish">Oops!</span> Page not found
        </h6>

        <p class="mb-8 text-center text-gray-500 md:text-lg">
          The page you’re looking for doesn’t exist.
        </p>

        <router-link
          to="/"
          class="px-6 py-2 text-sm font-semibold text-yellow-800 bg-yellow-200 outline-none focus:ring focus:ring-yellow-400"
          >Go home</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-not-found",
};
</script>
