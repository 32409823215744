<template>
  <div class="home-container" :class="[station && station.shortcode ? `station-${station.shortcode}` : '']">
    <history-bubbles />
    <!-- <Snowf :amount="50" :size="5" :speed="1.5" :wind="0" :opacity="0.8" :swing="1" :image="null" :zIndex="0"
      :resize="true" color="#fff" /> -->
  </div>
</template>

<script>
import HistoryBubbles from "@/components/Layout/HistoryBubbles";
import { mapGetters } from "vuex";

// import Snowf from 'vue-snowf';

export default {
  name: "home-page",
  components: {
    HistoryBubbles,
    // Snowf
  },
  computed: {
    ...mapGetters(["station"]),
  },
};
</script>
