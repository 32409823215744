<template>
  <div class="flex flex-col flex-wrap items-start justify-between p-3 md:flex-row" :class="{
    'hxsd:flex-row': !!optionsMobileExpandedState,
    'hxsd:flex-col flex-wrap': !optionsMobileExpandedState
  }">
    <div class="flex items-center justify-between flex-1 w-full p-2 text-center md:p-0 md:pt-0 hxs:w-auto md:w-auto">
      <button @click.prevent="togglePlayback"
        class="transition duration-300 rounded-full outline-none btn-playback sm:flex sm:items-center sm:flex-shrink-0 focus:shadow-outline"
        aria-label="Toggle Playback" :disabled="isOffline">
        <template>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
            class="w-12 h-12 p-2 bg-red-800 rounded-full shadow-inner fill-current" v-if="isOffline">
            <path
              d="M22.75,11.251H17.871l-1.606-4.2a1.25,1.25,0,0,0-2.392.2l-1.458,7.116L10.6,2.314a1.25,1.25,0,0,0-2.438-.158L5.548,11.251H1.25a1.25,1.25,0,0,0,0,2.5H6.489a1.248,1.248,0,0,0,1.2-.906L8.979,8.356l2.013,13.331a1.25,1.25,0,0,0,1.2,1.063h.033a1.25,1.25,0,0,0,1.224-1l2.009-9.8.382,1a1.249,1.249,0,0,0,1.167.8H22.75a1.25,1.25,0,0,0,0-2.5Z"
              fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-12 h-12 fill-current"
            v-else-if="!isPlaying">
            <path
              d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12C23.993,5.376,18.624,0.007,12,0z M16.828,12.894l-7.381,3.69 l0,0c-0.494,0.247-1.095,0.047-1.342-0.448C8.036,15.998,8,15.845,8,15.69V8.308c0-0.552,0.448-1,1-1 c0.155,0,0.307,0.036,0.446,0.105l7.383,3.687c0.495,0.245,0.698,0.845,0.453,1.34c-0.097,0.197-0.257,0.356-0.454,0.453V12.894z"
              stroke="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-12 h-12 fill-current"
            v-else-if="!audio.volume">
            <path
              d="M9.5,17.5c-0.001-2.16,0.874-4.228,2.424-5.731C11.972,11.722,12,11.658,12,11.591V1c-0.001-0.552-0.449-0.999-1.001-0.999 c-0.265,0-0.519,0.106-0.706,0.293L4.366,6.221C4.319,6.268,4.255,6.294,4.189,6.294H2c-1.105,0-2,0.895-2,2c0,0,0,0,0,0v3.414 c0,1.105,0.895,2,2,2h2.189c0.066,0,0.13,0.026,0.177,0.073l4.751,4.752c0.097,0.098,0.256,0.098,0.354,0.001 c0.053-0.053,0.079-0.126,0.072-0.201C9.514,18.056,9.5,17.778,9.5,17.5z M17.5,11c-3.59,0-6.5,2.91-6.5,6.5s2.91,6.5,6.5,6.5 s6.5-2.91,6.5-6.5C23.996,13.912,21.088,11.004,17.5,11z M20.151,19.091c0.303,0.282,0.32,0.757,0.037,1.06s-0.757,0.32-1.06,0.037 c-0.013-0.012-0.025-0.025-0.037-0.037l-1.414-1.414c-0.098-0.097-0.256-0.097-0.354,0l-1.414,1.414 c-0.282,0.303-0.757,0.32-1.06,0.037s-0.32-0.757-0.037-1.06c0.012-0.013,0.025-0.025,0.037-0.037l1.414-1.414 c0.097-0.098,0.097-0.256,0-0.354l-1.414-1.414c-0.282-0.303-0.266-0.778,0.037-1.06c0.288-0.268,0.735-0.268,1.023,0l1.414,1.414 c0.098,0.097,0.256,0.097,0.354,0l1.414-1.414c0.282-0.303,0.757-0.32,1.06-0.037s0.32,0.757,0.037,1.06 c-0.012,0.013-0.025,0.025-0.037,0.037l-1.414,1.414c-0.097,0.098-0.097,0.256,0,0.354L20.151,19.091z"
              stroke="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-12 h-12 fill-current" v-else>
            <path
              d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12C23.993,5.376,18.624,0.007,12,0z M12,21.55 c-5.275,0-9.551-4.276-9.551-9.551S6.725,2.448,12,2.448s9.551,4.276,9.551,9.551c0,0,0,0.001,0,0.001 C21.544,17.272,17.272,21.544,12,21.55z M8.831,7.142h1.441c0.541,0,0.98,0.439,0.98,0.98v8.816c0,0.541-0.439,0.98-0.98,0.98 H8.831c-0.541,0-0.98-0.439-0.98-0.98V8.122C7.851,7.581,8.29,7.142,8.831,7.142z M13.701,7.101h1.496 c0.541,0,0.98,0.439,0.98,0.98v8.816c0,0.541-0.439,0.98-0.98,0.98h-1.496c-0.541,0-0.98-0.439-0.98-0.98V8.081 C12.721,7.54,13.16,7.101,13.701,7.101z"
              stroke="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </template>
      </button>
      <div
        class="flex flex-col flex-1 py-2 ml-2 overflow-hidden text-left transition duration-300 sm:ml-0 sm:pl-4 md:py-0 hxsd:w-full hxsd:justify-between">
        <span class="flex-row block track-title" translate="no" v-if="track.title">
          {{ track.title }}
        </span>
        <span class="flex-row block track-artist" translate="no" v-if="track.artist">
          {{ track.artist }}
        </span>
        <!--<span class="text-white track-album" v-if="track.album">{{ track.album }}</span>-->
      </div>
      <button type="button"
        class="flex-shrink-0 hidden ml-auto transition duration-300 rounded-full outline-none hxsd:flex btn-playback hxsd:items-center focus:shadow-outline"
        v-on:click.prevent="toggleSettingsPane" aria-label="Toggle Settings Pane"
        :aria-expanded="optionsMobileExpandedState">
        <template>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-8 h-8 m-1 fill-current"
            v-if="optionsMobileExpandedState">
            <path
              d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z"
              fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
            <path
              d="M17.769,10.654a1.783,1.783,0,0,0-1.7-2.953l-1.411.327a.453.453,0,0,1-.533-.311L13.7,6.325a1.776,1.776,0,0,0-3.4,0L9.876,7.717a.454.454,0,0,1-.533.311L7.932,7.7a1.783,1.783,0,0,0-1.7,2.952l.988,1.066a.463.463,0,0,1,0,.625l-.988,1.065a1.783,1.783,0,0,0,1.7,2.952l1.411-.326a.451.451,0,0,1,.533.311l.424,1.392a1.776,1.776,0,0,0,3.4,0l.424-1.392a.449.449,0,0,1,.533-.311l1.411.326A1.738,1.738,0,0,0,18,15.521a1.757,1.757,0,0,0-.234-2.112l-.988-1.065a.463.463,0,0,1,0-.625ZM12,14.281a2.25,2.25,0,1,1,2.25-2.25A2.25,2.25,0,0,1,12,14.281Z"
              fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-8 h-8 m-1 fill-current" v-else>
            <path
              d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z"
              fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
            <path
              d="M17.666,6.333a1.249,1.249,0,0,0-1.768,0l-3.722,3.722a.25.25,0,0,1-.353,0L8.1,6.333A1.25,1.25,0,1,0,6.333,8.1l3.722,3.722a.25.25,0,0,1,0,.354L6.333,15.9a1.251,1.251,0,0,0,0,1.768,1.269,1.269,0,0,0,1.768,0l3.722-3.722a.249.249,0,0,1,.353,0L15.9,17.666a1.272,1.272,0,0,0,1.768,0,1.251,1.251,0,0,0,0-1.768l-3.722-3.722a.25.25,0,0,1,0-.354L17.666,8.1A1.249,1.249,0,0,0,17.666,6.333Z"
              fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path>
          </svg>
        </template>
      </button>
    </div>
    <div class="items-center block w-full md:items-right md:w-auto" :aria-expanded="optionsMobileExpandedState" :class="{
      'hxsd:block': !optionsMobileExpandedState,
      'hxsd:hidden': !!optionsMobileExpandedState,
    }">
      <!--       <div class="p-2 lg:p-0" v-if="sources.length>1">
      </div> -->
      <div class="items-center block sm:flex">
        <div class="relative inline-block w-full mb-6 md:w-64 md:mr-2 sm:mb-0" v-if="sources">
          <select v-on:change="changeSource($event)" class="source-select" aria-label="Select Current Source">
            <option v-for="(source, index) in sources" :key="`source-${index}`" :value="index">
              {{ source.title + " (" + source.quality + ")" }}
            </option>
          </select>
          <div class="absolute inset-y-0 right-0 flex items-center px-2 text-yellow-100 pointer-events-none">
            <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        <div class="items-center block w-full px-2 mb-4 sm:flex-row sm:mb-0 md:px-0">
          <input class="block volume-slider" type="range" min="0" max="10" step=".5" v-model="volume"
            aria-label="Change Volume" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueOfflineMixin } from "vue-offline";
import { mapState } from "vuex";
// import NchanSubscriber from 'nchan'
export default {
  name: "player-element",
  mixins: [VueOfflineMixin],
  data: function () {
    return {
      socket: undefined,
      isPlaying: false,
      volume: 8,
      track: {
        title: "",
        artist: "",
        album: "",
        length: undefined,
      },
      audio: undefined,
      current: undefined,
      // sources: ,
      info: undefined,
      optionsMobileExpandedState: true,
    };
  },
  created: function () {
    this.audio = new Audio();
    this.setTrack("");
  },
  beforeMount: function () {
    this.setAudioVolume(this.volume);
    this.setCurrent(this.sources[0]);
    // this.grabStationData(this.current.station_id);
  },
  mounted: function () {
    this.grabStationData();
    this.startStationDataPolling();
    //this.initEventSource();

    this.$on("offline", () => {
      this.resetStationDataPolling();
      this.resetStationData();
      this.stopPlayback();
      this.setTrack("Offline", "Kijk je internetconnectie na.");
    });

    this.$on("online", () => {
      this.grabStationData();
      this.startStationDataPolling();
    });
  },
  beforeDestroy: function () {
    this.stopPlayback();
  },
  methods: {
    /*initEventSource() {
      let socket_url = this.currentSocketUrl;
      if(!(this.sub instanceof NchanSubscriber) || this.sub.url != socket_url){
        if(this.sub instanceof NchanSubscriber) this.sub.stop();
        this.sub = new NchanSubscriber(socket_url, { subscriber:'eventsource' });
        this.sub.on('message', message => (this.info = JSON.parse(message)))
        this.sub.on('error', function() {
          this.sub.stop();
          // fallback to intervalling a request
          if(!this.station_data_interval) {
            this.startStationDataPolling()
          }
        });
        this.sub.start();
      }
    },*/
    resetStationDataPolling() {
      this.station_data_interval = null;
    },
    startStationDataPolling() {
      this.station_data_interval = setInterval(
        function () {
          this.grabStationData();
        }.bind(this),
        30000
      );
    },
    grabStationData() {
      this.$http.get(this.currentApiUrl).then((response) => (this.info = response.data));
    },
    resetStationData() {
      this.info = null;
    },
    setAudioVolume: function (newVolume) {
      this.audio.volume = newVolume / 10;
      this.$emit("volume-changed", this.audio.volume);
    },
    togglePlayback: function () {
      if (!this.isPlaying) {
        if (!this.audio.volume) {
          this.volume = 1;
        }
        this.startPlayback();
      } else {
        if (this.audio.volume) {
          this.stopPlayback();
        } else {
          this.volume = 1;
        }
      }
      // this.grabStationData(this.current.station_id)
    },
    stopPlayback: function () {
      this.audio.pause();
      this.isPlaying = false;
      this.$emit("player-stopped");
    },
    startPlayback: function () {
      this.reloadAudioSource();
      this.audio.play();
      this.isPlaying = true;
      this.$emit("player-started");
    },
    changeSource: function (event) {
      this.setCurrent(this.sources[event.target.value]);
    },
    setCurrent: function (stream) {
      //console.log('setCurrent', this.current, 'against', stream);
      if (!this.current || stream != this.current) {
        this.current = stream;
        this.$emit("player-changed-source" /*, event.target.value*/);
      }
    },
    setTrack: function (title, artist, album, length) {
      // console.log(title,artist);
      this.track.artist = artist || "";
      this.track.title = title || "";
      this.track.album = album || "";
      this.track.length = length || undefined;
      this.$emit("track-changed", this.track);
    },
    reloadAudioSource: function () {
      this.audio.src = this.current.source;
    },
    toggleSettingsPane: function () {
      this.optionsMobileExpandedState = !this.optionsMobileExpandedState;
    },
  },
  watch: {
    audio: {
      handler: function () {
        this.isPlaying = !this.audio.paused;
      },
      deep: true,
    },
    track: {
      handler(to) {
        document.title =
          (to.title || "") +
          (to.title && to.artist ? " - " : "") +
          (to.artist || "") +
          (to.title || to.artist ? " • " : "") +
          "starradio.online";
      },
      deep: true,
    },
    volume: function (newVolume, oldVolume) {
      if (newVolume != oldVolume || newVolume != this.audio.volume * 10) {
        this.setAudioVolume(newVolume);
      }
    },
    info: function () {
      if (this.info.now_playing.song) {
        this.setTrack(
          this.info.now_playing.song.title || "",
          this.info.now_playing.song.artist || "",
          this.info.now_playing.song.album || "",
          this.info.now_playing.song.length || undefined
        );
      } else {
        this.setTrack(this.streamTitle);
      }
      this.$store.commit("updateInfo", this.info);
      this.$emit("info-updated", this.info);
    },
    current: function (newStr, lastStr) {
      let isPlayingTemp = this.isPlaying;
      if (isPlayingTemp) this.stopPlayback();
      this.reloadAudioSource();
      if (
        typeof newStr == "object" &&
        newStr.station_shortcode &&
        typeof lastStr == "object" &&
        lastStr.station_shortcode &&
        newStr.station_shortcode != lastStr.station_shortcode
      ) {
        this.setTrack(this.streamTitle);
      }
      //this.initEventSource()

      this.resetStationDataPolling();
      this.grabStationData();
      this.startStationDataPolling();

      this.$emit("source-changed");
      if (isPlayingTemp) this.startPlayback();
    },
  },
  computed: {
    ...mapState(["sources"]),
    now: function () {
      return Date.now();
    },
    muted: function () {
      return this.audio instanceof Audio && !this.audio.volume;
    },
    plays: function () {
      return this.audio instanceof Audio && !this.audio.paused;
    },
    streamTitle: function () {
      return this.current.title || "";
    },
    currentSocketUrl: function () {
      return (
        this.current.server_url + "/api/live/nowplaying/" + this.current.station_shortcode
      );
    },
    currentApiUrl: function () {
      return (
        this.current.server_url + "/api/nowplaying/" + this.current.station_shortcode
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.track-title {
  /* @apply text-yellow-400; */
  color: #794b23;
  font-size: 12px;
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.track-artist {
  color: #683c15;
  font-size: 10px;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.track-album {
  color: #3d3d3d;
  font-size: 8px;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.source-select {
  @apply block appearance-none outline-none w-full bg-transparent text-yellow-200 text-sm py-3 px-4 pr-8 leading-tight;

  &:hover {
    @apply text-white;
    cursor: pointer;
  }

  /* &:focus {
    @apply outline;
  } */
  >option {
    @apply text-yellow-100 text-xs;
    background-color: #111;
    line-height: 2em;
    height: 2em;
    padding: 1em;
  }
}

/* Input Range */
input.volume-slider[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: auto;
  padding: 0;
  position: relative;
  background: transparent;
}

input.volume-slider[type="range"]::after {
  content: "";
  position: absolute;
  right: 0;
  top: calc(100% - 36px);
  bottom: calc(100% - 36px);
  display: inline-block;
  width: calc(100% / 1.6);
  background: transparent;
  z-index: 0;
}

.btn-playback {
  @apply text-white outline-none;

  &:hover {
    @apply text-yellow-200;
  }

  &:focus {
    @apply text-yellow-200;
  }
}

input.volume-slider[type="range"] *,
input.volume-slider[type="range"]:focus,
input.volume-slider[type="range"]:focus-within {
  @apply outline-none;
}

input.volume-slider[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #2d283f;
  border-radius: 50px;
  border: 0 solid #c9c9c9;
}

input.volume-slider[type="range"]::-webkit-slider-thumb {
  transform: translateZ(0);
  border: 2px solid #2d283f;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
  position: relative;
  z-index: 1;
}

/* input.volume-slider[type="range"]:focus::-webkit-slider-runnable-track {
  @apply shadow-outline;
} */
input.volume-slider[type="range"]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #2d283f;
  border-radius: 0;
  border: 0 solid #c9c9c9;
  border-radius: 50px;
}

input.volume-slider[type="range"]::-moz-range-thumb {
  transform: translateZ(0);
  border: 2px solid #2d283f;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  background: #fff;
  cursor: pointer;
}

input.volume-slider[type="range"]::-ms-track {
  width: 150px;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
  border-radius: 50px;
}

input.volume-slider[type="range"]::-ms-fill-lower {
  background: #c9c9c9;
  border: 0 solid #c9c9c9;
  border-radius: 0;
}

input.volume-slider[type="range"]::-ms-fill-upper {
  background: #c9c9c9;
  border: 0 solid #c9c9c9;
  border-radius: 0;
}

input.volume-slider[type="range"]::-ms-thumb {
  transform: translateZ(0);
  border: 2px solid #2d283f;
  height: 16px;
  width: 16px;
  border-radius: 50px;
  background: #fff;
  cursor: pointer;
  margin-top: 0;
}

input.volume-slider[type="range"]:focus::-ms-fill-lower {
  background: #c9c9c9;
}

input.volume-slider[type="range"]:focus::-ms-fill-upper {
  background: #c9c9c9;
}
</style>
