<template>
  <div class="bubbles">
    <div class="bubble now_playing" v-if="now_playing">
      <div class="cover" v-lazy:background-image="albumPicture(now_playing.song)"></div>
      <div v-if="now_playing.song" class="song">
        <div v-if="now_playing.song && now_playing.song.title" class="title">
          {{ now_playing.song.title }}
        </div>
        <div v-if="now_playing.song && now_playing.song.artist" class="artist">
          {{ now_playing.song.artist }}
        </div>
      </div>
    </div>
    <div class="bubble" v-for="(item, index) in filteredHistory" :class="`history-${index}`" :key="`history-${index}`">
      <div class="cover" v-lazy:background-image="albumPicture(item.song)"></div>
      <div v-if="item.song" class="song">
        <div v-if="item.song && item.song.title" class="title">
          {{ item.song.title }}
        </div>
        <div v-if="item.song && item.song.artist" class="artist">
          {{ item.song.artist }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "history-bubbles",
  /* props: {
    now_playing: Object,
    song_history: Array,
  }, */
  computed: {
    ...mapGetters(["song_history", "now_playing"]),
    filteredHistory() {
      return this.song_history?.slice(0, 3);
    },
  },
  methods: {
    albumPicture(song) {
      let path =
        "https://hub.starradio.online/album.php" +
        "?station=starradio&artist_first=y";
      if (song) {
        if (song.artist)
          path =
            path +
            "&artist=" +
            encodeURIComponent(song.artist)
              .replace(/-/g, "%2D")
              .replace(/_/g, "%5F")
              .replace(/\./g, "%2E")
              .replace(/!/g, "%21")
              .replace(/~/g, "%7E")
              .replace(/\*/g, "%2A")
              .replace(/'/g, "%27")
              .replace(/\(/g, "%28")
              .replace(/\)/g, "%29");
        if (song.title)
          path =
            path +
            "&title=" +
            encodeURIComponent(song.title)
              .replace(/-/g, "%2D")
              .replace(/_/g, "%5F")
              .replace(/\./g, "%2E")
              .replace(/!/g, "%21")
              .replace(/~/g, "%7E")
              .replace(/\*/g, "%2A")
              .replace(/'/g, "%27")
              .replace(/\(/g, "%28")
              .replace(/\)/g, "%29");
      }
      return path;
    },
  },
};
</script>
