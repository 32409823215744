<template>
  <div id="app" class="flex flex-col">
    <NavBar id="navbar" />
    <template v-if="$route.matched.length">
      <router-view class="flex-1"></router-view>
    </template>
    <PlayerElement id="player" />
  </div>
</template>

<script>
import NavBar from "@/components/Layout/NavBar";
import PlayerElement from "@/components/Layout/PlayerElement";

export default {
  name: "app",
  components: {
    NavBar,
    PlayerElement,
  },
};
</script>